<template>
  <header>
    <nav class="navbar navbar-expand-md" data-bs-theme="dark">
      <div class="container-fluid">

        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        
        <a class="navbar-brand" href="https://www.peugeot.com.my/" target="_blank">
          <img class="logo" src="@/assets/img/peugeot.svg" alt="Peugeot" width="112">
        </a>

        

        <div class="d-flex offcanvas offcanvas-start p-md-0" tabindex="-1" id="navbarNav">
          <button type="button" class="btn-close d-md-none" data-bs-dismiss="offcanvas" aria-label="Close"></button>

          <ul class="navbar-nav me-auto mb-0">
            <li class="nav-item">
              <a href="/" class="nav-link" :class="{ active: isActive('/') }">
                Weekender Experience
              </a>
            </li>
            <li class="nav-item">
              <a href="/book" class="nav-link" :class="{ active: isActive('/book') }">
                Book Now
              </a>
            </li>
          </ul>
          <a href="https://www.peugeot.com.my/tools/find-a-dealer.html" class="header-map mt-2 mt-md-0 d-md-block d-flex justify-content-end flex-row-reverse align-items-center" target="_blank">
            <!-- <span class="me-2 ms-2 ms-md-0">Find A Dealer</span> -->
            <img src="@/assets/img/icon-pin.svg" alt="pin" width="23">
          </a>

        </div>

      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  setup() {
    const isActive = (path) => {
      return window.location.pathname === path;
    };

    return { isActive };
  }
};
</script>